.boxBgLight {
    border: 1px solid #d35400;
    border-radius: 3px;
}

.boxBgLight > button {
    background: transparent;
    border: 0;
}

.playerInfo {
    display: flex;
    flex: 1;
    align-items: center;
    font-size: 20px;
    padding: 10px;
}

.playerInfo > a {
    font-weight: bold;
}

.playerLabel {
    font-size: 20px;
    font-weight: lighter;
}