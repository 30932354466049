[data-theme="dark"] {
  color: #eee;
  background-color: #272727;
}
[data-theme="dark"] nav.custom-nav > ul > li > a { 
  color: #eee;
}
[data-theme="dark"] nav.custom-nav > ul > li > button {
  color: #eee; 
}
[data-theme="dark"] .card {
  background-color: #272727;
  border-color: #eee;
}
[data-theme="dark"] .list-group-item {
  background-color: #272727;
  border-color: #eee;
}
[data-theme="dark"] .btn-outline-dark {
  color: #eee;
  border-color: #eee;
}
[data-theme="dark"] .instruction-icon {
  background-color: #363636;
}

a{
  color: #d35400;
}

a:hover {
  text-decoration: none;
}

.btn-clear {
  background: transparent;
  border: 0;
}

.theme-btn {
  font-size: 30px;
}

*:focus {
  outline: 0 !important;
}

.card.custom a.card-title {
  font-size: 20px;
}

.form-control:focus, .btn:focus {
  box-shadow: none;
}

nav.custom-nav > ul > li:not(:last-child) {
  margin-right: 15px;
}

nav.custom-nav > ul > li > a, button {
  color: #34495e;
  font-weight: bold;
  font-size: 18px;
}

nav.custom-nav > ul > li > a.nav-active, button.nav-active {
  color: #d35400;
}

.instruction-icon {
  background-color: #e3e3e3;
  padding: 30px 10px;
}